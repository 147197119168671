body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
  box-sizing: border-box;
}
.auth {
  width: 100%;
  height: 100vh;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth h1 {
  margin-top: 0;
}
.auth-inner {
  padding: 1.5rem 1rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .auth-inner {
    padding: 2.5rem;
  }
}
.input {
  display: flex;
  flex-direction: column;
}
.input label {
  margin-bottom: 4px;
}
.input input {
  border: 1px solid #000;
  padding: 0.5rem;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 1rem;
}
.input input[type="submit"] {
  background: #242424;
  color: #fff;
  border: none;
}
.error {
  padding: 0.5rem;
  background: #ff3a3a;
  color: #fff;
  margin-bottom: 0.35rem;
}
.container {
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 1740px;
}
.licenses {
  margin-bottom: 7rem;
}
.table {
  width: 100%;
  /*   overflow-x: auto; */
  position: relative;
}
.table-inner {
  width: 100%;
  /*     min-width: 870px; */
}
.table--heading {
  display: inline-flex;
  width: 100%;
  padding: 1rem;
  background: #e9e9e9;
}
.table--heading-item h3 {
  margin: 0;
  font-size: 16px;
  color: #3f3f3f;
}
.table--heading-item:nth-child(1) {
  flex: 0.2;
}
.table--heading-item:nth-child(2) {
  flex: 0.3;
}
.table--heading-item:nth-child(3) {
  flex: 0.3;
}
.table--heading-item:nth-child(4) {
  flex: 0.2;
}
.table--heading-item:nth-child(5) {
  flex: 0.3;
}
.table--heading-item:nth-child(6) {
  flex: 0.2;
}
.table--content {
  width: 100%;
}
.table--content-row {
  width: 100%;
  padding: 1rem;
  display: inline-flex;
  background: #f9f9f9;
}
.table--content-row-item {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.table--content-row-item h5 {
  max-width: calc(100% - 2rem);
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table--content-row-item:nth-child(1) {
  flex: 0.2;
}
.table--content-row-item:nth-child(2) {
  flex: 0.3;
}
.table--content-row-item:nth-child(3) {
  flex: 0.3;
}
.table--content-row-item:nth-child(4) {
  flex: 0.2;
}
.table--content-row-item:nth-child(5) {
  flex: 0.3;
}
.table--content-row-item:nth-child(6) {
  flex: 0.2;
}
.table--content-row-item .input input {
  margin-bottom: 0;
  padding: 0.25rem;
  font-size: 14px;
}
